<template>
  <div>
    <MusicType
      :width="952"
      :type="type"
      :tabsList="tabsList"
    ></MusicType>
    <router-view></router-view>
  </div>
</template>

<script>
import MusicType from "@/components/content/MusicType.vue";
export default {
  props: {},
  data() {
    return {
		type: false,
      // tabs数据
      tabsList: [
        // {
        //   name: "Reference",
        //   url: "/activity/reference",
        // },
        // {
        //   name: this.$t('songcollection'),
        //   url: "/activity/songcollect",
        // },
        // {
        //   name: this.$t('discountsection'),
        //   url: "/activity/discountzone",
        // },
      ],
    };
  },
  components: { MusicType },
  computed: {},
  created() {},
  mounted() {
	  if(this.$store.state.identity != 3){
		  let arr = [
			{
			  name: "Reference",
			  url: "/activity/reference",
			},
			// {
			//   name: this.$t('songcollection'),
			//   url: "/activity/songcollect",
			// },
			{
			  name: this.$t('discountsection'),
			  url: "/activity/discountzone",
			},  
		  ]
		  this.tabsList = arr
	  }else {
		  let arr = [
		  			{
		  			  name: "Reference",
		  			  url: "/activity/reference",
		  			},
		  			{
		  			  name: this.$t('songcollection'),
		  			  url: "/activity/songcollect",
		  			},
		  			{
		  			  name: this.$t('discountsection'),
		  			  url: "/activity/discountzone",
		  			},  
		  ]
		  this.tabsList = arr
	  }
  },
  watch: {},
  methods: {},
};
</script>

<style scoped lang='less'>
</style>
